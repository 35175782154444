import { createApp } from 'vue';

import 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';

/* import the fontawesome core */

/* import font awesome icon component */
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */

import EventBox from '@/components/EventBox.vue';

/* add icons to the library */

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 30000;
const app = createApp(App);

app.mixin({
  methods: {
    slugifyUrls(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    },
  },
});

app.component('EventBox', EventBox);

app
  .use(store)

  .use(router)
  //.component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
