<template>
  <Loader />
  <div id="wrapper">
    <header class="header">
      <!-- Jumbotron -->
      <div class="p-3 text-center cls-2 border-bottom">
        <div class="container">
          <div class="row gy-3">
            <!-- Left elements -->
            <div class="col-lg-2 col-sm-4 col-4">
              <h1 @click="goHome" class="float-start">JusTix</h1>
            </div>
            <!-- Left elements -->

            <!-- Center elements -->
            <div class="order-lg-last col-lg-5 col-sm-8 col-8">
              <div class="d-flex float-end">
                <button
                  @click="loginHandler"
                  type="button"
                  class="btn bg-6 me-2"
                >
                  <span class="icon">
                    <i class="fas fa-user m-1 me-md-2"></i>
                  </span>
                  <span class="d-none d-md-inline"> {{ isLoggedIn }}</span>
                </button>

                <a href="/login">
                  <button type="button" class="btn bg-6 me-2">
                    <span class="icon">
                      <i class="fas fa-heart m-1 me-md-2"></i>
                    </span>
                    <span class="d-none d-md-inline"> Wishlist</span>
                  </button>
                </a>
                <button @click="goToCart" type="button" class="btn bg-3 me-2">
                  <span class="icon">
                    <i class="fas fa-shopping-cart m-1 me-md-2"></i>
                  </span>
                  <span class="badge badge-warning" id="lblCartCount">
                    {{ cartItemCount }}
                  </span>
                  <span class="d-none d-md-inline"> Cart</span>
                </button>
              </div>
            </div>
            <!-- Center elements -->

            <!-- Right elements -->
            <div class="col-lg-5 col-md-12 col-12">
              <form
                onsubmit="event.preventDefault()"
                class="d-flex me-2"
                role="search"
              >
                <input
                  class="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button class="btn bg-6" type="submit">
                  <i class="fas text-white fa-search"></i>
                </button>
              </form>
            </div>
            <!-- Right elements -->
          </div>
        </div>
      </div>
      <!-- Jumbotron -->

      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <!-- Container wrapper -->
        <div
          class="container justify-content-center justify-content-md-between"
        >
          <!-- Toggle button -->
          <button
            class="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <!-- Collapsible wrapper -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link text-dark" aria-current="page" href="/"
                  >Home</a
                >
              </li>
              <!-- Navbar dropdown -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-dark"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  Categories
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item" href="#">Action</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Another action</a>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="/events">Events</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="/venues">Venues</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="/competitions"
                  >Competitions</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="#">News</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="account/tickets/"
                  >My Tickets</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link text-dark" href="account/orders/"
                  >My Orders</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- Navbar -->
      <!-- Jumbotron -->
    </header>

    <section class="section">
      <div class="container d-flex flex-column min-vh-100">
        <router-view />
      </div>
    </section>

    <div class="container">
      <footer
        class="d-flex mt-auto flex-wrap justify-content-between align-items-center py-3 my-4 border-top"
      >
        <div class="col-md-4 d-flex align-items-center">
          <a
            href="/"
            class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
          >
            <svg class="bi" width="30" height="24">
              <use xlink:href="#bootstrap"></use>
            </svg>
          </a>
          <span class="mb-3 mb-md-0 text-body-secondary"
            >© 2023 Company, Inc</span
          >
        </div>

        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3">
            <a class="text-body-secondary" href="#"
              ><svg class="bi" width="24" height="24">
                <use xlink:href="#twitter"></use>
              </svg>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-body-secondary" href="#"
              ><svg class="bi" width="24" height="24">
                <use xlink:href="#instagram"></use>
              </svg>
            </a>
          </li>
          <li class="ms-3">
            <a class="text-body-secondary" href="#"
              ><svg class="bi" width="24" height="24">
                <use xlink:href="#facebook"></use>
              </svg>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script lang="">
//import bootstrap javascript
import 'bootstrap';
import Loader from './components/Loader.vue';

export default {
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: [],
      },
      token: null,
      itemCount: 0,
      isLoading: false,
    };
  },
  components: {
    Loader,
  },
  watch: {
    cartItemCount() {
      this.itemCount = this.cartItemCount;
    },
    //watch for this.$store.getters.isLoggedIn to change
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },

  methods: {
    goToCart() {
      if (this.cartItemCount > 0) {
        this.$router.push('/cart');
      } else {
        alert('Your cart is empty');
      }
    },

    doLogout() {
      this.$store.commit('clearToken');
      this.$router.push({ name: 'Login' });
    },

    loginHandler() {
      if (this.token) {
        return this.$router.push({ name: 'Account' });
      } else {
        return this.$router.push({ name: 'Login' });
      }
    },
    goHome() {
      this.$router.push('/');
    },
  },
  beforeCreate() {
    //initialize the store
    this.$store.commit('initializeStore');
  },
  mounted() {
    this.cart = this.$store.state.cart;
    this.token = this.$store.state.token;
    this.isLoading = this.$store.state.isLoading;
  },

  computed: {
    mapState() {
      this.cart = this.$store.state.cart;
      this.token = this.$store.state.token;
      return this.$store.state;
    },

    isLoggedIn() {
      //check if user is logged in using the state getters

      if (this.$store.getters.isLoggedIn) {
        return ' Account';
      } else {
        return ' Login/Signup';
      }
    },

    cartItemCount() {
      return this.$store.getters.cartTotal;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

.header {
  background: #6f5b92;
  color: #fff;
}
#lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-4:hover {
  background-color: #b65c32;
  color: #fff;
}
</style>
